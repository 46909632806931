import { useWindowWidth } from '@react-hook/window-size';
import React, { CSSProperties } from 'react';
import Trophies from 'src/assets/icons/trophies.png';
import Footer from 'src/components/Footer';
import NavBar from 'src/components/Navbar';
import Text from 'src/components/Text';
import { mineshaft, primary, tundora, white } from 'src/utils/Colors';
import Button from 'src/components/Button';

import { NEW_RESULTS, NewResult } from './NewResults';

const MOBILE_BREAKPOINT = 1000;

const Results = () => {
    const windowWidth = useWindowWidth();
    const styles = responsiveStyles(windowWidth);

    return (
        <>
            <NavBar simple />
            <div style={styles.contentWrap}>
                <div style={styles.content}>
                    <Text
                        large
                        black
                        color={mineshaft}
                        customStyle={{ margin: 0 }}
                    >
                        results.
                    </Text>
                    <hr style={styles.titleLine} />
                    <Text
                        bold
                        customStyle={{
                            lineHeight: 1.5,
                            marginBottom: 30,
                            fontSize: 20
                        }}
                        color={tundora}
                    >
                        Competition results from each of our annual tournaments
                        are available below. Please click on the buttons to view
                        the results!
                    </Text>

                    {NEW_RESULTS.map((result: NewResult, i: number) => (
                        <div style={styles.resultsContainer}>
                            <Button
                                type="sharp_wide"
                                rel="noopener noreferrer"
                                href={result.linkSrc}
                                shouldOpenInNewTab
                            >
                                <Text color={white} customStyle={{ margin: 0 }}>
                                    {' '}
                                    {result.year} {'INVITATIONAL'}{' '}
                                </Text>
                            </Button>
                        </div>
                    ))}

                    <div style={{ marginBottom: 80 }} />
                </div>
                <div style={styles.trophies}>
                    <img src={Trophies} alt="" />
                </div>
            </div>
            <Footer />
        </>
    );
};
const responsiveStyles = (windowWidth: number) => ({
    resultsContainer: {
        marginTop: windowWidth > 700 ? '20px' : '10px'
    } as CSSProperties,
    contentWrap: {
        position: 'relative',
        width: '100%'
    } as CSSProperties,
    content: {
        padding:
            windowWidth > MOBILE_BREAKPOINT
                ? '160px 120px 120px 120px'
                : '160px 60px 80px 60px',
        minHeight: 'calc(100vh - 300px)',
        maxWidth: 800,
        margin: '0 auto'
    } as CSSProperties,
    titleLine: {
        border: 0,
        borderTop: `3px solid ${primary}`,
        display: 'inline-block',
        width: 80,
        color: primary
    } as CSSProperties,
    trophies: {
        position: 'absolute',
        margin: 0,
        right: 30,
        bottom: -5,
        zIndex: 1
    } as CSSProperties
});

export default Results;

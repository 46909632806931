import React, { CSSProperties } from 'react';
import Text from 'src/components/Text';
import { mineshaft, primary, tundora } from 'src/utils/Colors';
import Button from 'src/components/Button';

const Involvement = () => (
    <div style={styles.section}>
        <Text large black color={mineshaft} customStyle={{ margin: 0 }}>
            involvement.
        </Text>
        <hr style={styles.titleLine} />
        <Text customStyle={{ lineHeight: 1.5, marginTop: 30 }} color={tundora}>
            Are you a Yale student or eager Science Olympiad alumni that wants
            to help organize or run events at our invitational? We have several
            way for you to get involved. After reading the descriptions below,
            apply for the positions best suited towards your circumstances.
        </Text>
        <ul>
            <li>
                <Text customStyle={{ lineHeight: 1.5 }} color={tundora}>
                    <b>Board | </b> This is a very selective and high commitment
                    role available for Yale undergraduate students only. The
                    members of the YUSO board will engage in weekly meetings
                    with specialized roles in preparation for the invitational.
                    If you are a Yale undergraduate student who would like to
                    apply, please watch out for next year's application around
                    fall 2024.
                </Text>
            </li>

            <li>
                <Text customStyle={{ lineHeight: 1.5 }} color={tundora}>
                    <b>Event Supervisor/Exam Review Committee | </b> The Event
                    Supervisors will be reponsible for writing exams and running
                    the event during the day of the tournament, whereas the exam
                    review committee will check over exams to ensure sufficient
                    test quality. Thus, both positions require significant
                    Science Olympiad experience. If you are a interested, please
                    watch out for next year's application around fall 2024.
                </Text>
            </li>

            <li>
                <Text customStyle={{ lineHeight: 1.5 }} color={tundora}>
                    <b>General Volunteer/Assistant Event Supervisor | </b> The
                    General Volunteer and Assistant ES positions require the
                    lowest degree of commitment. These people will be
                    responsible for helping out with various tasks on the day of
                    the invitational. Assistant Event Supervisors will shadow
                    the Event Supervisors, and may have to run the event if the
                    Event Supervisor is not available on the day of the
                    competition. If you are interested, please visit the form
                    below.
                </Text>
            </li>
        </ul>
        <Button
            customStyle={{ marginTop: 10.0 }}
            type="round_big"
            rel="noopener noreferrer"
            href="https://docs.google.com/forms/d/1cb8auVQy7CkS2n6tSzyinp-LhB7B-EEmXmzgl8u9S70/viewform"
            shouldOpenInNewTab
        >
            Volunteer
        </Button>
    </div>
);

const styles = {
    section: {
        width: '100%',
        display: 'inline-block',
        textAlign: 'left',
        marginBottom: 60
    } as CSSProperties,
    titleLine: {
        border: 0,
        borderTop: `3px solid ${primary}`,
        display: 'inline-block',
        width: 80,
        color: primary
    } as CSSProperties,
    yaleIcon: {
        position: 'absolute',
        width: 200,
        height: 'auto',
        resizeMode: 'contain',
        margin: 0,
        right: 200,
        bottom: 0,
        zIndex: 1
    } as CSSProperties
};

export default Involvement;

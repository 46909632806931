import './styles.css';

import React, { CSSProperties } from 'react';

type Props = {
    customStyle?: CSSProperties;
    medium_bold?: boolean;
    bold?: boolean;
    black?: boolean;
    color?: string;
    h1?: boolean;
    small?: boolean; // 14px
    medium?: boolean; // 24px
    medium_large?: boolean; // 32px
    large?: boolean; // 40px
    title_small?: boolean; // 48px
    title_medium?: boolean; // 72 px
    title_large?: boolean; // 96px
    fontSize?: string | number;
    margin?: [string | number, string | number]; // [leftMargin, topMargin]
    onPress?: () => void;
    children: any;

    // decorations
    staticBlueUnderline?: boolean;

    // animated hover effects
    blueUnderline?: boolean;
    blueHover?: boolean;
    darkenHover?: boolean;
};

const combine_dicts = (
    dict1: Record<string, any>,
    dict2: Record<string, any>
) => {
    // Dict2 overwrites existing values in dict1
    for (var key in dict2) {
        dict1[key] = dict2[key];
    }
    return dict1;
};
const convert_number_to_pixel = (num: string | number) => {
    // Either converts a number to its pixel equivalent or uses the string value
    if (typeof num === 'number') {
        return num.toString() + 'px';
    }
    return num;
};

const parseMargin = (
    margin: [string | number, string | number] | undefined
) => {
    if (margin) {
        return {
            marginLeft: convert_number_to_pixel(margin[0]),
            marginTop: convert_number_to_pixel(margin[1])
        };
    }
    return {
        marginLeft: '0px',
        marginTop: '0px'
    };
};

const Text = (props: Props) => {
    const {
        small,
        medium,
        medium_large,
        large,
        title_small,
        title_medium,
        title_large,
        fontSize,
        color,
        medium_bold,
        bold,
        black,
        margin,
        h1,
        customStyle,
        children,
        staticBlueUnderline,
        blueUnderline,
        blueHover,
        darkenHover,
        onPress
    } = props;

    let style: CSSProperties = {
        fontFamily: 'Roboto-Regular',
        color: color ? color : '#4c4848',
        fontSize: '14px'
    };
    style = combine_dicts(style, parseMargin(margin));
    if (h1) {
        style = combine_dicts(
            {
                fontSize: '24px',
                marginTop: '0.67em',
                marginBottom: '0.67em'
            },
            style
        );
    }
    if (small) {
        style.fontSize = '14px';
    }
    if (medium) {
        style.fontSize = '24px';
    }
    if (medium_large){
        style.fontSize = '32px';
    }
    if (large) {
        style.fontSize = '40px';
    }
    if (title_small) {
        style.fontSize = '48px';
    }
    if (title_medium) {
        style.fontSize = '72px';
    }
    if (title_large){
        style.fontSize = '96px';
    }
    if (fontSize) {
        style.fontSize = convert_number_to_pixel(fontSize);
    }
    if (customStyle) {
        style = combine_dicts(style, customStyle);
    }
    if (medium_bold) {
        style.fontFamily = 'Roboto-Medium';
    }
    if (bold) {
        style.fontFamily = 'Roboto-Bold';
    }
    if (black) {
        style.fontFamily = 'Roboto-Black';
    }
    return (
        <p
            onClick={() => {
                if (onPress) onPress();
            }}
            style={style}
            className={
                (blueUnderline ? 'blue_underline' : '') +
                (blueHover ? ' blue_hover' : '') +
                (staticBlueUnderline ? ' static_blue_underline' : '') +
                (darkenHover ? ' hover-darken' : '')
            }
        >
            {children}
        </p>
    );
};

export default Text;

import { useWindowWidth } from '@react-hook/window-size';
import React, { CSSProperties, useState } from 'react';
import AnimateHeight from 'react-animate-height';
import Text from 'src/components/Text';
import { primary, tundora } from 'src/utils/Colors';

const MOBILE_BREAKPOINT = 1100;

interface Props {
    bio: string;
    photo_src: string;
    name: string;
    title: string;
}

const TeamProfile = (props: Props) => {
    const { bio, photo_src, name, title } = props;
    const windowWidth = useWindowWidth();
    const styles = responsiveStyles(windowWidth);

    const [bioToggleText, setBioToggleText] = useState<string>('view bio');
    const [bioOpen, setBioOpen] = useState<boolean>(false);

    const toggleBio = () => {
        setBioOpen(!bioOpen);
        setBioToggleText(
            bioToggleText === 'view bio' ? 'hide bio' : 'view bio'
        );
    };

    return (
        <div style={styles.container}>
            <div style={styles.mainContent}>
                <img src={photo_src} style={styles.photo} alt="" />
                <div style={styles.description}>
                    <Text bold fontSize={20} customStyle={{ marginBottom: 5 }}>
                        {name}
                    </Text>
                    <Text fontSize={16} customStyle={{ marginBottom: 5 }}>
                        {title}
                    </Text>
                    <Text
                        onPress={toggleBio}
                        bold
                        fontSize={16}
                        color={primary}
                        darkenHover
                        customStyle={styles.viewBioButton}
                    >
                        {bioToggleText}
                    </Text>
                </div>
            </div>
            <AnimateHeight duration={500} height={bioOpen ? 'auto' : '0'}>
                <div style={styles.profile_bio}>
                    <Text color={tundora}>{bio}</Text>
                </div>
            </AnimateHeight>
        </div>
    );
};

const responsiveStyles = (windowWidth: number) => {
    const isLargeScreen = windowWidth > MOBILE_BREAKPOINT;
    return {
        container: {
            width: isLargeScreen ? 420 : '100%',
            marginBottom: 30
        } as CSSProperties,
        mainContent: {
            width: '100%',
            display: 'flex',
            flexDirection: isLargeScreen ? 'row' : 'column',
            alignItems: 'center',
            marginRight: isLargeScreen ? 30 : 0
        } as CSSProperties,
        photo: {
            width: 160,
            height: 160,
            borderRadius: '50%',
            objectFit: 'cover',
            marginRight: isLargeScreen ? 30 : 0
        } as CSSProperties,
        description: {
            display: 'inline-block',
            marginTop: isLargeScreen ? 0 : 20,
            textAlign: isLargeScreen ? 'left' : 'center'
        } as CSSProperties,
        viewBioButton: {
            cursor: 'pointer'
        } as CSSProperties,
        profile_bio: {
            padding: isLargeScreen ? '30px 30px 0 0' : 0,
            lineHeight: 1.5
        } as CSSProperties
    };
};

export default TeamProfile;

import CindyPhoto from 'src/assets/people/cindy.jpg';
import KePhoto from 'src/assets/people/ke.jpg';
import PhucPhoto from 'src/assets/people/phuc.jpg';
import SydneyPhoto from 'src/assets/people/sydney.jpg';
import JingPhoto from 'src/assets/people/jing.jpg';
import EmilyPoagPhoto from 'src/assets/people/emilypoag.jpg';
import SheelPhoto from 'src/assets/people/sheel.jpg';
import AnhThaiPhoto from 'src/assets/people/anhthai.jpg';
import EmilyPengPhoto from 'src/assets/people/emilypeng.jpg';
import ChrisPhoto from 'src/assets/people/chris.jpg';
import AidanPhoto from 'src/assets/people/aidan.jpg';
import LeoPhoto from 'src/assets/people/leo.jpg';
import AnthonyPhoto from 'src/assets/people/anthony.jpg';
import TimPhoto from 'src/assets/people/tim.jpg';
import IrenePhoto from 'src/assets/people/irene.jpg';
import GavinPhoto from 'src/assets/people/gavin.jpg';
import JudyPhoto from 'src/assets/people/judy.jpg';
import XinZhiPhoto from 'src/assets/people/xinzhi.jpg';
import SharnaPhoto from 'src/assets/people/sharna.jpg';
import CindyMeiPhoto from 'src/assets/people/cindymei.jpg';
import DavidPhoto from 'src/assets/people/david.jpg';
import JeffreyPhoto from 'src/assets/people/jeffrey.jpg';
import KJPhoto from 'src/assets/people/kj.jpg'
import HarveyPhoto from 'src/assets/people/harvey.jpg';
import LisaPhoto from 'src/assets/people/lisa.jpg';

export interface ProfileInfo {
    photo: string;
    name: string;
    title: string;
    bio: string;
}

export interface TeamInfo {
    teamName: string;
    people: ProfileInfo[];
}

export const FULL_ROSTER: TeamInfo[] = [
    {
        teamName: 'Tournament Directors',
        people: [
            {
                photo: SheelPhoto,
                name: 'Sheel Trivedi',
                title: 'Director',
                bio:
                    'Sheel is a sophomore from Orlando, Florida majoring in Neuroscience. He participated in Science Olympiad for six years throughout middle and high school. His favorite events were Water Quality, Green Generation, and Potions and Poisons. In his free time, he likes to spend time in the Saybrook Squiche, distract his friends while studying in Bass, and try all the local coffee shops in New Haven.'
            },
            {
                photo: LeoPhoto,
                name: 'Leo Shen',
                title: 'Subdirector',
                bio:
                    'Leo is a first-year from Auburn, Alabama and is majoring in Biomedical Engineering. He has competed in Science Olympiad for five years with Auburn High School in both Division B and C, focusing on Ornithology and Fossils. In his free time, Leo enjoys dancing, spending time with friends, and exploring the local food scene.'
            }
        ]
    },
    {
        teamName: 'Events Directors',
        people: [
            {
                photo: AnhThaiPhoto,
                name: 'Anh-Thai Le',
                title: 'Director of Events',
                bio:
                    'Anh-Thai is a sophomore from Southern California planning to major in Mathematics and Physics. He competed in Science Olympiad for 3 years with Beckman High School and was build captain his senior year. In his free time, Anh-Thai likes to binge watch YouTube videos and (try not to) play Brawl Stars.'
            },
            {
                photo: AnthonyPhoto,
                name: 'Anthony Vazquez',
                title: 'Co-Director of Events',
                bio:
                    'Anthony is a first-year from Long Island, NY. He participated in Science Olympiad at Chaminade High School served as President his senior year. In high school, his favorite events were Dynamic Planet and Rocks and Minerals. He is planning on majoring in Molecular, Cellular, and Developmental Biology on the pre-med track. In his free time, he loves playing basketball, practicing the violin, and playing NBA 2K.'
            },
            {
                photo: IrenePhoto,
                name: 'Irene Zheng',
                title: 'Co-Director of Events',
                bio:
                    "Irene is a first-year from Bloomington, Minnesota planning to major in applied mathematics. During her time with Jefferson High School's Science Olympiad Team, she enjoyed competing in forensics, code-busters, and ornithology. Outside of YUSO, Irene can be found dancing, listening to music on hammocks, and going on boba runs!"
            },
            {
                photo: TimPhoto,
                name: 'Tim Li',
                title: 'Co-Director of Events',
                bio:
                    'Tim is a first-year from Madison, Wisconsin planning to major in Applied Mathematics and Computer Science. He competed in Science Olympiad for seven years and was a captain for West High School his senior year. His favorite event is Experimental Design and Fermi Questions (╯°□°)╯︵ ┻━┻. In his free time, he likes to play basketball, watch movies, and hang out with friends.'
            }
        ]
    },
    {
        teamName: 'Logistics',
        people: [
            {
                photo: AidanPhoto,
                name: 'Aidan Pulmano',
                title: 'Director of Logistics',
                bio:
                    'Aidan is a sophomore from Sugar Land, Texas majoring in Chemical Engineering. He competed in Science Olympiad with Clements High School for two years. Outside of YUSO, Aidan likes to research, conduct night-runs with his suitemates, and FaceTime his two younger brothers back home.'
            },
            {
                photo: GavinPhoto,
                name: 'Gavin Sun',
                title: 'Co-Director of Logistics',
                bio:
                    "Gavin is a freshman from St. Paul, Minnesota pursuing a tentative major in Economics and S&DS. He competed in Science Olympiad throughout all four years at Woodbury High School, and his favorite events include Green Generation, It's About Time, and Machines. In his free time, he enjoys exploring the local food scene, stargazing, and keeping up with current events."
            },
            {
                photo: JudyPhoto,
                name: 'Judy Chen',
                title: 'Co-Director of Logistics',
                bio:
                    "Judy is a first-year from New York who is planning on majoring in neuroscience. She has half a year of Science Olympiad experience at Stuyvesant High School, and she's excited to be more involved in it during college! Outside of Scioly, she is a part of Simplex Sciences and Yale HOSA. She enjoys watching and playing water sports, going to the beach, and riding amusement park rides."
            }
        ]
    },
    {
        teamName: 'Human Resources',
        people: [
            {
                photo: JingPhoto,
                name: 'Jing Lin',
                title: 'Human Resources',
                bio:
                    'Jing is a junior from Atlanta, Georgia and is majoring in Molecular Biochemistry and Biophysics. She competed in Science Olympiad for three years and served as a team leader and Co-president for two years at Forsyth Central High School. Jing likes to draw and goes on nature walks while listening to music in her free time.'
            }
        ]
    },
    {
        teamName: 'Finance',
        people: [
            {
                photo: EmilyPoagPhoto,
                name: 'Emily Poag',
                title: 'Director of Finance',
                bio:
                    'Emily is a sophomore at Yale (Class of 2026) from Ames, IA. She is a chemistry major who also enjoys taking economics classes. In high school her favorite Science Olympiad events were Forensics and Codebusters. In her free time she enjoys reading, going on runs, getting boba, crocheting, and hanging out with her friends.'
            },
            {
                photo: XinZhiPhoto,
                name: 'Xinzhi Qiu',
                title: 'Finance',
                bio:
                    'Xinzhi is a freshman from Singapore and is a prospective double major in Molecular, Cellular & Developmental Biology and Economics. He has never competed in Science Olympiad, so he is very excited to try it out! In his free time, he enjoys cheering on Chelsea Football Club (in the UK), and watching the latest flicks on the big screen.'
            },
            {
                photo: EmilyPengPhoto,
                name: 'Emily Peng',
                title: 'Finance and Advisor',
                bio:
                    'Emily is junior from Portland, Maine and is majoring in physics and applied math. She has never competed in Science Olympiad, but has found it to be very fulfilling in college! In her free time, she enjoys all things arts and crafts (making stickers!) and eating açaí bowls.'
            }
        ]
    },
    {
        teamName: 'Outreach',
        people: [
            {
                photo: CindyMeiPhoto,
                name: 'Cindy Mei',
                title: 'Outreach',
                bio:
                    'Cindy is a junior majoring in Neuroscience. She is from Chicago, IL, and was captain of the Division B team at Whitney M. Young Magnet High School. Her favorite SciOly events were Fast Facts and Anatomy & Physiology (and she swore off all building events after Bottle Rocket). Outside of class, she enjoys playing video games with friends, exploring and ranking restaurants on Beli, and writing (whether on blogs or Wattpad).'
            },
            {
                photo: SharnaPhoto,
                name: 'Sharna Saha',
                title: 'Outreach',
                bio:
                    ''
            }
        ]
    },
    {
        teamName: 'Engineering & Design',
        people: [
            {
                photo: PhucPhoto,
                name: 'Phuc Duong',
                title: 'Director of Engineering',
                bio:
                    'Phuc is a junior from Massachusetts majoring in Computer Science. He competed in Science Olympiad for 4 years and served as the treasurer for two years at South High Community School. His favorite Science Olympiad event was Game On. Outside of YUSO, Phuc enjoys binge watching shows and going on road trips with friends. '
            },
            {
                photo: DavidPhoto,
                name: 'David Dong',
                title: 'Software Engineer',
                bio:
                    'David is a first-year from Iowa and has participated in Science Olympiad for six years. His favorite events were Wifi Lab and Write it Do it. He loves hiking, LaTeX, and poetry. He goes to cheap boba shops in his free time.'
            },
            {
                photo: JeffreyPhoto,
                name: 'Jeffrey Wei',
                title: 'Software Engineer',
                bio:
                    'Jeffrey is a first-year from Acton, Massachusetts majoring in physics and computer science.  He competed for Acton Boxborough Regional High School for four years as a member and later captain. His favorite events were Astronomy, Fermi Questions, and Trajectory when the build worked properly. Outside of YUSO, Jeffrey enjoys supporting Arsenal, listening to music, meeting up with friends, and finding good burrito spots.'
            }
        ]
    },
    {
        teamName: 'Graphic Design',
        people: [
            {
                photo: HarveyPhoto,
                name: 'Harvey Lloyd Picar',
                title: 'Graphic Designer',
                bio:
                    'Harvey is from Hawaii and attended Waipahu High School, competing in multiple scioly events such as Codebusters, Machines, Detector Building, and Astronomy. He is currently a junior, majoring in Computer Science. His other hobbies include playing video games and volleyball for fun!'
            },
            {
                photo: LisaPhoto,
                name: 'Lisa Chou',
                title: 'Graphic Designer',
                bio:
                    'Lisa is a freshman from Texas who is planning on majoring in Electrical Engineering. She competed in Science Olympiad for six years throughout middle and high school. Outside of YUSO, you can find Lisa drinking boba with friends.'
            }
        ]
    },
    {
        teamName: 'Advisors',
        people: [
            {
                photo: KJPhoto,
                name: 'KJ McConnell',
                title: 'Advisor',
                bio:
                    'KJ is a junior from Charlotte, NC majoring in Astrophysics (and maybe Stats & Data Science). She served as the director of YUSO in the 2023 season and captained her high school team at Providence Day School for 2 years! Outside of YUSO, KJ serves as an aide for her residential college and can be found crocheting in all manner of places.'
            },
            {
                photo: SydneyPhoto,
                name: 'Sydney Scott',
                title: 'Advisor',
                bio:
                    'Sydney is a senior from Long Island, New York and is studying Biomedical Engineering. She competed in Science Olympiad for three years with Ward Melville High School. Her favorite events are Code Busters and Experimental Design. In her free time, you can find her designing for student publications, finding new sunset viewing spots on campus, and eating ice cream with friends.'
            },
            {
                photo: CindyPhoto,
                name: 'Cindy Nguyen',
                title: 'Advisor',
                bio:
                    'Cindy is a junior from Worcester, Massachusetts studying Molecular, Cellular, and Developmental Biology. She was a co-captain for the Science Olympiad team at South High Community School and has competed since her middle school years. In her free time, she enjoys crocheting, biking down the canal trail, and watching movies with friends.'
            },
            {
                photo: KePhoto,
                name: 'Ke Lin',
                title: 'Advisor',
                bio:
                    'Ke is a senior from Bismarck, North Dakota majoring in Molecular Biophysics and Biochemistry. She competed in Science Olympiad for four years with Century High School. Outside of YUSO, Ke can be found wandering around campus with bubble tea in hand, trying not to get run over at intersections. In her free time, she loves to scrapbook, dance, and watch TwoSetViolin.'
            },
            {
                photo: ChrisPhoto,
                name: 'Chris Esneault',
                title: 'Advisor',
                bio:
                    'Chris Esneault is a junior from Brookfield, Connecticut, and is majoring in Molecular, Cellular, and Developmental Biology. He has participated in Science Olympiad competitively in middle school and has volunteered at the high school level at University of Connecticut. In his free time, he enjoys volunteering at Yale-New Haven Hospital, doing yoga, and baking.'
            }
        ]
    }
];
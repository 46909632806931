import { useWindowWidth } from '@react-hook/window-size';
import React, { CSSProperties } from 'react';
import Text from 'src/components/Text';
import { mineshaft, primary } from 'src/utils/Colors';

import { ProfileInfo, TeamInfo } from '../Roster';
import TeamProfile from './TeamProfile';

const MOBILE_BREAKPOINT = 900;

interface Props {
    teamInfo: TeamInfo;
}

const TeamSection = (props: Props) => {
    const { teamInfo } = props;
    const windowWidth = useWindowWidth();
    const styles = responsiveStyles(windowWidth);

    return (
        <div style={styles.teamContainer}>
            <Text
                fontSize={25}
                black
                color={mineshaft}
                customStyle={{ margin: 0 }}
            >
                {teamInfo.teamName.toLowerCase()}
            </Text>
            <hr style={styles.titleLine} />
            <div style={styles.profiles}>
                {teamInfo.people.map((profile: ProfileInfo, i: number) => (
                    <TeamProfile
                        bio={profile.bio}
                        photo_src={profile.photo}
                        name={profile.name}
                        title={profile.title}
                        key={i}
                    />
                ))}
            </div>
        </div>
    );
};

const responsiveStyles = (windowWidth: number) => ({
    teamContainer: {
        width: '100%',
        display: 'inline-block',
        textAlign: 'left',
        marginBottom: 60
    } as CSSProperties,
    titleLine: {
        border: 0,
        borderTop: `3px solid ${primary}`,
        display: 'inline-block',
        width: 80,
        color: primary
    } as CSSProperties,
    profiles: {
        width: '100%',
        display: 'flex',
        flexWrap: 'wrap',
        flexDirection: windowWidth > MOBILE_BREAKPOINT ? 'row' : 'column',
        paddingTop: 30
    } as CSSProperties
});

export default TeamSection;

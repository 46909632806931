import { useWindowWidth } from '@react-hook/window-size';
import React, { CSSProperties } from 'react';
import Bush from 'src/assets/icons/bush.png';
import TreeClump from 'src/assets/icons/tree_clump.png';
import Footer from 'src/components/Footer';
import NavBar from 'src/components/Navbar';
import Text from 'src/components/Text';
import { mineshaft, primary, tundora } from 'src/utils/Colors';

const MOBILE_BREAKPOINT = 1000;

const About = () => {
    const windowWidth = useWindowWidth();
    const styles = responsiveStyles(windowWidth);

    return (
        <>
            <NavBar simple />
            <div style={styles.contentWrap}>
                <div style={styles.content}>
                    <div style={styles.section}>
                        <Text
                            large
                            black
                            color={mineshaft}
                            customStyle={{ margin: 0 }}
                        >
                            our story<span style={{ color: primary }}>.</span>
                        </Text>
                        <hr style={styles.titleLine} />
                        <Text
                            customStyle={{ lineHeight: 1.5, marginTop: 30 }}
                            color={tundora}
                        >
                            YUSO is the first collegiate Science Olympiad
                            invitational, and was originally founded as Science
                            Olympiad Volunteers in 2013 by Ike Lee '15, Xiyu
                            Wang '15, and Nick Billmyer '16. The three were
                            looking to remain involved with Science Olympiad in
                            college, and started to volunteer at local
                            competitions. They quickly found that hosting an
                            invitational on Yale's campus was the best way to
                            help the SciOly community in the Northeast,
                            especially the newer developing teams in
                            Connecticut. By working with state directors,
                            regional coordinators, the University, teachers, and
                            countless Yale students, we put together the
                            nation’s first college student-run tournament in
                            January 2014 and continue to host an invitational
                            every year.
                        </Text>
                    </div>
                    <div style={styles.section}>
                        <Text
                            large
                            black
                            color={mineshaft}
                            customStyle={{ margin: 0 }}
                        >
                            our mission<span style={{ color: primary }}>.</span>
                        </Text>
                        <hr style={styles.titleLine} />
                        <Text
                            customStyle={{ lineHeight: 1.5, marginTop: 30 }}
                            color={tundora}
                        >
                            Our mission is simple. We want to leverage Yale
                            University’s capital and human resources as best as
                            we can to deliver a high quality Science Olympiad
                            product for high school students and teachers. It is
                            common for teams in the Midwest to attend multiple
                            invitationals prior to their regional or state
                            competitions. Why shouldn’t Northeastern teams have
                            that luxury? We are here to serve the Northeast
                            region at large but are also particularly interested
                            in helping local Connecticut teams expand their SO
                            programs by allowing them easy access to
                            competitions in their backyard.
                        </Text>
                        <Text
                            customStyle={{ lineHeight: 1.5, marginTop: 10 }}
                            color={tundora}
                        >
                            Since 2001, the University has invested over $1
                            billion in research funding, curriculum expansions,
                            faculty appointments, and building renovations in
                            the sciences and engineering departments. As
                            students at a university that is traditionally
                            focused on the humanities and social sciences, we’re
                            glad that there’s a place at Yale for Science
                            Olympiad.
                        </Text>
                        <Text
                            customStyle={{ lineHeight: 1.5, marginTop: 10 }}
                            color={tundora}
                        >
                            As SO alumni, we have all had our horizons
                            broadened, self-doubts challenged, and intellectual
                            capacities expanded through Science Olympiad. Many
                            of us owe our curiosity for STEM fields to our high
                            school SO experiences and it’s important to us that
                            today’s high school students in the Northeast have
                            even better opportunities to develop their
                            interests.
                        </Text>
                        <Text
                            customStyle={{ lineHeight: 1.5, marginTop: 10 }}
                            color={tundora}
                        >
                            Yale has a long tradition of community and public
                            service. We hope that our commitment to hosting
                            quality Science Olympiad competitions for local
                            teams upholds that tradition faithfully and helps to
                            motivate high school students’ love for science,
                            much as we were inspired not so long ago.
                        </Text>
                        <Text
                            customStyle={{ lineHeight: 1.5, marginTop: 10 }}
                            color={tundora}
                        >
                            * The views expressed here are those of YUSO’s
                            members and do not necessarily reflect the opinion
                            of the University.
                        </Text>
                    </div>
                </div>
                <img src={Bush} style={styles.bushIcon} alt="" />
                <img src={TreeClump} style={styles.treesIcon} alt="" />
            </div>
            <Footer />
        </>
    );
};

const responsiveStyles = (windowWidth: number) => ({
    contentWrap: {
        position: 'relative',
        width: '100%'
    } as CSSProperties,
    content: {
        padding:
            windowWidth > MOBILE_BREAKPOINT
                ? '160px 120px 120px 120px'
                : '160px 60px 80px 60px',
        minHeight: 'calc(100vh - 300px)',
        maxWidth: 600,
        margin: '0 auto'
    } as CSSProperties,
    section: {
        width: '100%',
        display: 'inline-block',
        textAlign: 'left',
        marginBottom: 60
    } as CSSProperties,
    titleLine: {
        border: 0,
        borderTop: `3px solid ${primary}`,
        display: 'inline-block',
        width: 80,
        color: primary
    } as CSSProperties,
    bushIcon: {
        position: 'absolute',
        width: 100,
        margin: 0,
        left: 0,
        bottom: -30,
        zIndex: 1
    } as CSSProperties,
    treesIcon: {
        position: 'absolute',
        width: 100,
        margin: 0,
        right: 0,
        bottom: 0,
        zIndex: 1
    } as CSSProperties
});

export default About;

import './styles.css';

import React, { CSSProperties, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';
import Text from 'src/components/Text';
import { primary, white } from 'src/utils/Colors';

import { TEXT_ROUTES, TextRoute } from '../';

const CollapsedMenu = () => {
    const [isCollapsed, setIsCollapsed] = useState<boolean>(true);

    return (
        <div style={styles.container}>
            <i
                onClick={() => setIsCollapsed(!isCollapsed)}
                className="fas fa-bars hover-darken"
                aria-label="menu"
                style={styles.menuIcon}
            />
            <CSSTransition
                in={!isCollapsed}
                timeout={300}
                classNames="top_right"
                unmountOnExit
            >
                <div style={styles.menuContainer}>
                    {TEXT_ROUTES.map((textRoute: TextRoute, i: number) => (
                        <MenuItem textRoute={textRoute} key={i} />
                    ))}
                </div>
            </CSSTransition>
            {!isCollapsed && (
                <div
                    onClick={() => setIsCollapsed(true)}
                    style={styles.clickListener}
                />
            )}
        </div>
    );
};

const MenuItem = (props: { textRoute: TextRoute }) => {
    const { name, route } = props.textRoute;
    const history = useHistory();

    return (
        <div
            className="hover-darken"
            onClick={() => history.push(route)}
            style={styles.menuItem}
        >
            <Text customStyle={{ margin: 0, fontFamily: 'Roboto-Medium' }}>
                {name}
            </Text>
        </div>
    );
};

const styles = {
    container: {
        position: 'absolute',
        top: 0,
        right: 30
    } as CSSProperties,
    menuIcon: {
        margin: '35px 30px 30px 30px',
        fontSize: 20,
        color: primary,
        cursor: 'pointer'
    } as CSSProperties,
    clickListener: {
        position: 'fixed',
        zIndex: 1,
        width: '100vw',
        height: '100vh',
        left: 0,
        top: 0
    } as CSSProperties,
    menuContainer: {
        position: 'absolute',
        overflow: 'hidden',
        top: 65,
        right: 30,
        background: white,
        borderRadius: 10,
        boxShadow: '0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23)',
        zIndex: 3
    } as CSSProperties,
    menuItem: {
        background: white,
        padding: '20px 30px 20px 30px',
        cursor: 'pointer'
    } as CSSProperties
};

export default CollapsedMenu;

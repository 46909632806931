export interface NewResult {
    year: string;
    linkSrc: string;
}

export const NEW_RESULTS: NewResult[] = [
    {
        year: '2024',
        linkSrc:
            'https://www.duosmium.org/results/2024-02-10_yale_invitational_c/'
    },
    {
        year: '2023',
        linkSrc:
            'https://www.duosmium.org/results/2023-02-04_yale_invitational_c/'
    },
    {
        year: '2022',
        linkSrc:
            'https://www.duosmium.org/results/2022-02-12_yale_invitational_c/'
    },
    {
        year: '2021',
        linkSrc:
            'https://www.duosmium.org/results/2021-02-06_yale_invitational_c/'
    },
    {
        year: '2020',
        linkSrc:
            'https://www.duosmium.org/results/2020-02-01_yale_invitational_c/'
    },
    {
        year: '2019',
        linkSrc:
            'https://www.duosmium.org/results/2019-02-02_yale_invitational_c/'
    },
    {
        year: '2018',
        linkSrc:
            'https://www.duosmium.org/results/2018-01-27_yale_invitational_c/'
    },
    {
        year: '2017',
        linkSrc:
            'https://www.duosmium.org/results/2017-01-21_yale_invitational_c/'
    },
    {
        year: '2016',
        linkSrc:
            'https://www.duosmium.org/results/2016-01-30_yale_invitational_c/'
    },
    {
        year: '2015',
        linkSrc:
            'https://www.duosmium.org/results/2015-01-17_yale_invitational_c/'
    },
    {
        year: '2014',
        linkSrc:
            'https://www.duosmium.org/results/2014-01-18_yale_invitational_c/'
    }
];

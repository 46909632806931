import { useWindowWidth } from '@react-hook/window-size';
import React, { CSSProperties } from 'react';
import Harkness from 'src/assets/icons/harkness.png';
import Footer from 'src/components/Footer';
import NavBar from 'src/components/Navbar';

import Details from './components/Details';
import Involvement from './components/Involvement';
import Rules from './components/Rules';
// import TournamentInfo from './components/TournamentInfo';
// import FinalResults from './components/FinalResults';

const MOBILE_BREAKPOINT = 1000;

const Tournament = () => {
    const windowWidth = useWindowWidth();
    const styles = responsiveStyles(windowWidth);

    return (
        <>
            <NavBar simple />
            <div style={styles.contentWrap}>
                <div style={styles.content}>
                    <Details />
                    <Rules />
                    {/* <TournamentInfo /> */}
                    <Involvement />
                </div>
                <img src={Harkness} style={styles.yaleIcon} alt="" />
            </div>
            <Footer />
        </>
    );
};
const responsiveStyles = (windowWidth: number) => ({
    contentWrap: {
        position: 'relative',
        width: '100%'
    } as CSSProperties,
    content: {
        padding:
            windowWidth > MOBILE_BREAKPOINT
                ? '160px 120px 360px 120px'
                : '160px 60px 360px 60px',
        minHeight: 'calc(100vh - 300px)',
        maxWidth: 1200,
        margin: '0 auto'
    } as CSSProperties,
    yaleIcon: {
        position: 'absolute',
        width: 200,
        height: 'auto',
        resizeMode: 'contain',
        margin: 0,
        right: 200,
        bottom: 0,
        zIndex: 1
    } as CSSProperties
});
export default Tournament;

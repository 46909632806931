import React from 'react';
import { Route, Router, Switch } from 'react-router-dom';

import About from '../screens/About';
import Contact from '../screens/Contact';
import Home from '../screens/Home';
import Results from '../screens/Results';
import Sponsors from '../screens/Sponsors';
import Team from '../screens/Team';
import Tournament from '../screens/Tournament';

interface Props {
    history: any;
}

const Navigation = (props: Props) => {
    const { history } = props;
    return (
        <Router history={history}>
            <Switch>
                <Route exact path="/" component={Home} />
                <Route exact path="/about" component={About} />
                <Route exact path="/team" component={Team} />
                <Route exact path="/tournament" component={Tournament} />
                <Route exact path="/results" component={Results} />
                <Route exact path="/contact" component={Contact} />
                <Route exact path="/sponsors" component={Sponsors} />
            </Switch>
        </Router>
    );
};

export default Navigation;

import { useWindowWidth } from '@react-hook/window-size';
import React, { CSSProperties } from 'react';
import LeftCloud from 'src/assets/icons/cloud_1.png';
import MidCloud from 'src/assets/icons/cloud_2.png';
import RightCloud from 'src/assets/icons/cloud_3.png';
import Footer from 'src/components/Footer';
import NavBar from 'src/components/Navbar';
import Text from 'src/components/Text';
import { mineshaft, primary, white } from 'src/utils/Colors';

const MOBILE_BREAKPOINT = 1000;
const SMALLER_BREAKPOINT = 600;

const Contact = () => {
    const windowWidth = useWindowWidth();
    const styles = responsiveStyles(windowWidth);

    return (
        <>
            <NavBar simple />
            <div style={styles.contentWrap}>
                <div style={styles.content}>
                    <div style={styles.header}>
                        <Text
                            large
                            black
                            color={mineshaft}
                            customStyle={{ margin: 0 }}
                        >
                            contact us<span style={{ color: primary }}>.</span>
                        </Text>
                        <hr style={styles.titleLine} />
                        <Text bold fontSize={20}>
                            we respond fast!
                        </Text>
                    </div>
                    <div style={styles.contactBlock}>
                        <a
                            className="hover-darken"
                            href="mailto:yuso@yale.edu"
                            style={{ textDecoration: 'none' }}
                        >
                            <Text
                                black
                                fontSize={
                                    windowWidth > SMALLER_BREAKPOINT ? 36 : 26
                                }
                                color={white}
                                customStyle={{ margin: 0, cursor: 'pointer' }}
                            >
                                yuso@yale.edu
                            </Text>
                        </a>
                    </div>
                </div>
                <img src={LeftCloud} style={styles.leftCloud} alt="" />
                <img src={MidCloud} style={styles.midCloud} alt="" />
                <img src={RightCloud} style={styles.rightCloud} alt="" />
            </div>
            <Footer />
        </>
    );
};

const responsiveStyles = (windowWidth: number) => ({
    contentWrap: {
        position: 'relative',
        width: '100%'
    } as CSSProperties,
    content: {
        padding:
            windowWidth > MOBILE_BREAKPOINT
                ? '160px 120px 120px 120px'
                : '160px 60px 80px 60px',
        minHeight: 'calc(100vh - 500px)',
        maxWidth: 700,
        margin: '0 auto'
    } as CSSProperties,
    header: {
        width: '100%',
        display: 'inline-block',
        textAlign: 'left',
        marginBottom: 60
    } as CSSProperties,
    titleLine: {
        border: 0,
        borderTop: `3px solid ${primary}`,
        display: 'inline-block',
        width: 80,
        color: primary
    } as CSSProperties,
    contactBlock: {
        display: 'flex',
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        borderRadius: 15,
        padding: '80px 0 80px 0',
        background: primary
    } as CSSProperties,
    leftCloud: {
        width: 100,
        position: 'absolute',
        left: 0,
        bottom: -80
    } as CSSProperties,
    midCloud: {
        width: 100,
        position: 'absolute',
        left: '30%',
        bottom: -70
    } as CSSProperties,
    rightCloud: {
        width: 100,
        position: 'absolute',
        right: 0,
        bottom: -80
    } as CSSProperties
});

export default Contact;

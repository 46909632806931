import { useWindowWidth } from '@react-hook/window-size';
import React, { CSSProperties } from 'react';
import SML from 'src/assets/icons/sml.png';
import Footer from 'src/components/Footer';
import NavBar from 'src/components/Navbar';
import Text from 'src/components/Text';
import { mineshaft, primary, tundora } from 'src/utils/Colors';

import { Sponsor, SPONSORS } from './Sponsors';

const MOBILE_BREAKPOINT = 1000;
const Sponsors = () => {
    const windowWidth = useWindowWidth();
    const styles = responsiveStyles(windowWidth);

    return (
        <>
            <NavBar simple />
            <div style={styles.contentWrap}>
                <div style={styles.content}>
                    <Text large black color={mineshaft} customStyle={{ margin: 0 }}>
                        sponsors.
                    </Text>
                    <hr style={styles.titleLine} />
                    <Text 
                        bold
                        customStyle={{
                            lineHeight: 1.5,
                            marginBottom: 30,
                            fontSize: 20
                        }}
                        color={tundora}>
                        We would like to give a huge thanks to all of our sponsors for
                        providing the funding required for hosting our invitational.
                    </Text>
                    {SPONSORS.map((sponsor: Sponsor, i: number) => (
                        <div key={i}>
                            <a href={sponsor.websiteLink} style={styles.websiteLink}>
                                <Text
                                    black
                                    medium
                                    color={mineshaft}
                                    customStyle={{ margin: '30px 0px 0px 0px' }}
                                    blueHover
                                >
                                    {sponsor.name}
                                </Text>
                            </a>
                            <hr style={styles.sponsorLine[sponsor.tier]} />
                            <Text
                                customStyle={{ lineHeight: 1.5, marginTop: 15 }}
                                color={tundora}
                            >
                                {sponsor.description}
                            </Text>
                            <div style={styles.sponsorImgContainerCenter}>
                        		<img
                        	    	style={{
                                        ...styles.sponsorImg, 
                                        'width': sponsor.desiredSize ? sponsor.desiredSize : '100%'
                                    }}
                        	    	src={sponsor.sponsorSrc}
                        	    	alt=""
                        		/>
                            </div>
                        </div>
                    ))}
                    <Text 
                        bold
                        customStyle={{
                            lineHeight: 1.5,
                            	margin: '60px 0px',
                            	fontSize: 20
                        }}
                        color={tundora}>
                        want to become a sponsor? contact us at <a href="mailto:yuso@yale.edu" style = {styles.websiteLink}>yuso@yale.edu</a>
                    </Text>
                </div>
                <img src={SML} style={styles.smlIcon} alt="" />
            </div>
            <Footer />
        </>
    );
};
interface Tiers {
	[key: string]: CSSProperties;
}
const responsiveStyles = (windowWidth: number) => ({
    contentWrap: {
        position: 'relative',
        width: '100%'
    } as CSSProperties,
    content: {
        padding:
        windowWidth > MOBILE_BREAKPOINT
        ? '160px 120px 220px 120px'
        : '160px 60px 220px 60px',
        minHeight: 'calc(100vh - 300px)',
        maxWidth: 600,
        margin: '0 auto'
    } as CSSProperties,
    header: {
        width: '100%',
        display: 'inline-block',
        textAlign: 'left',
        marginBottom: 20
    } as CSSProperties,
    section: {
        width: '100%',
        display: 'inline-block',
        textAlign: 'left',
        marginBottom: 60
    } as CSSProperties,
    titleLine: {
        border: 0,
        borderTop: `3px solid ${primary}`,
        display: 'inline-block',
        width: 80,
        color: primary
    } as CSSProperties,
    sponsorLine: {
    	gold: {
        	border: 0,
        	borderTop: '3px solid gold',
        	display: 'inline-block',
        	width: 160,
        	color: 'gold'
        } as CSSProperties,
        silver: {
        	border: 0,
        	borderTop: '3px solid silver',
        	display: 'inline-block',
        	width: 160,
        	color: 'silver'
        } as CSSProperties,
        bronze: {
        	border: 0,
        	borderTop: '3px solid brown',
        	display: 'inline-block',
        	width: 160,
        	color: 'brown'
        } as CSSProperties,
        blue: {
        	border: 0,
        	borderTop: `3px solid ${primary}`,
        	display: 'inline-block',
        	width: 160,
        	color: primary
        } as CSSProperties
    } as Tiers,
    sponsorImgContainerCenter: {
    	width: "100%",
    	textAlign: "center"
    } as CSSProperties,
    sponsorImg: {
        marginTop: windowWidth > 700 ? '20px' : '5px',
        width: "100%",
        maxWidth: "200px",
        marginBottom: '2em'
    } as CSSProperties,
    oversizedSponsorImg: {
        marginTop: windowWidth > 700 ? '20px' : '5px',
        width: "30%",
        marginBottom: '2em'
    } as CSSProperties,
    websiteLink: {
        textDecoration: 'none',
        color: `${primary}`
    } as CSSProperties,
    smlIcon: {
        position: 'absolute',
        width: 250,
        height: 'auto',
        margin: 0,
        left: '50%',
        transform: 'translateX(-50%)',
        bottom: 0,
        zIndex: 1,
    } as CSSProperties
});

export default Sponsors;
import React, { CSSProperties } from 'react';
import Footer from 'src/components/Footer';
import NavBar from 'src/components/Navbar';

import Blurb from './components/Blurb';
import LookForward from './components/LookForward';
import MainBanner from './components/MainBanner';
import Statistics from './components/Statistics';

const Home = () => (
    <>
        <NavBar />
        <div style={styles.contentWrap}>
            <MainBanner />
            <Blurb />
            <Statistics />
            <LookForward />
        </div>
        <Footer />
    </>
);

const styles = {
    contentWrap: {
        position: 'relative',
        width: '100%'
    } as CSSProperties
};

export default Home;

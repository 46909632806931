import { useWindowWidth } from '@react-hook/window-size';
import React, { CSSProperties } from 'react';
import Competition from 'src/assets/icons/competition.png';
import Medal from 'src/assets/icons/medal.png';
import School from 'src/assets/icons/school.png';
import Diagonal from 'src/assets/icons/short_diagonal.png';
import Text from 'src/components/Text';
import { regal, white } from 'src/utils/Colors';

const MOBILE_BREAKPOINT = 1000;

const Statistics = () => {
    const windowWidth = useWindowWidth();
    const styles = responsiveStyles(windowWidth);

    return (
        <div style={styles.diagonalBackground}>
            <div style={styles.iconSection}>
                <div style={styles.iconGroup}>
                    <div style={styles.iconContainer}>
                        <img src={Competition} style={styles.icons} alt="" />
                        <Text bold color={white} customStyle={styles.iconText}>
                            11
                        </Text>
                        <Text bold color={white} customStyle={styles.iconText}>
                            years
                            <span style={{ color: regal }}>.</span>
                        </Text>
                    </div>
                    <div style={styles.iconContainer}>
                        <img src={Medal} style={styles.icons} alt="" />
                        <Text bold color={white} customStyle={styles.iconText}>
                            1235
                        </Text>
                        <Text bold color={white} customStyle={styles.iconText}>
                            medals
                            <span style={{ color: regal }}>.</span>
                        </Text>
                    </div>
                    <div style={styles.iconContainer}>
                        <img src={School} style={styles.icons} alt="" />
                        <Text bold color={white} customStyle={styles.iconText}>
                            100+
                        </Text>
                        <Text bold color={white} customStyle={styles.iconText}>
                            schools
                            <span style={{ color: regal }}>.</span>
                        </Text>
                    </div>
                </div>
                <Text
                    title_medium
                    black
                    color={white}
                    customStyle={{
                        textDecorationLine: 'underline',
                        textDecorationColor: `${regal}`,
                        marginTop: '15%'
                    }}
                >
                    Infinite possibilities
                    <span style={{ color: regal }}>.</span>
                </Text>
            </div>
        </div>
    );
};

const responsiveStyles = (windowWidth: number) => ({
    diagonalBackground: {
        backgroundImage: `url( ${Diagonal} )`,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        textDecoration: 'none',
        width: '100%',
        minHeight: 600,
        height: windowWidth > MOBILE_BREAKPOINT ? '100vw' : '100vh'
    } as CSSProperties,
    iconSection: {
        padding:
            windowWidth > MOBILE_BREAKPOINT
                ? '400px 120px 120px 120px'
                : '140px 0px 80px 0px',
        maxWidth: windowWidth > MOBILE_BREAKPOINT ? 1800 : '100%',
        textAlign: 'center'
    } as CSSProperties,
    iconGroup: {
        display: 'flex',
        flexWrap: 'wrap',
        flexDirection: 'row',
        margin: '0 auto',
        width: '80%',
        alignItems: 'center'
    } as CSSProperties,
    iconContainer: {
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        margin: '5%',
        width: '20%',
        textAlign: 'center'
    } as CSSProperties,
    icons: {
        flex: 1,
        width: '100%',
        margin: windowWidth > MOBILE_BREAKPOINT ? '0px 0px 50px 0px' : 0
    } as CSSProperties,
    iconText: {
        flex: 1,
        width: '100%',
        margin: windowWidth > MOBILE_BREAKPOINT ? '0px 0px 30px 0px' : 0,
        textDecorationLine: 'underline',
        textDecorationThickness: 5,
        textDecorationColor: `${regal}`,
        fontSize: windowWidth > MOBILE_BREAKPOINT ? 48 : 20
    } as CSSProperties
});

export default Statistics;

import React, { CSSProperties } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import Text from 'src/components/Text';
import { white } from 'src/utils/Colors';

interface TextPathProps {
    index: number;
    text: string;
    route: string;
}

const TextPath = (props: TextPathProps) => {
    const { route, text } = props;

    const history = useHistory();
    const location = useLocation();

    const isActive = route === location.pathname.substring(0, route.length);

    return (
        <li style={styles.textPath}>
            <div style={styles.routeWrap} onClick={() => history.push(route)}>
                <Text
                    h1
                    fontSize={16}
                    bold
                    color={white}
                    blueUnderline
                    staticBlueUnderline={isActive}
                    customStyle={{ marginBottom: 3 }}
                >
                    {text}
                </Text>
            </div>
        </li>
    );
};

const styles = {
    textPath: {
        height: 'calc(100px - 60px)',
        padding: '30px 30px 20px 30px',
        display: 'inline-block',
        listStyle: 'none'
    } as CSSProperties,
    routeWrap: {
        position: 'relative',
        display: 'inline-block',
        zIndex: 0,
        cursor: 'pointer'
    } as CSSProperties
};

export default TextPath;

import { createBrowserHistory } from 'history';
import React from 'react';

import Navigation from './navigation';

const history = createBrowserHistory({});

const App = () => <Navigation history={history} />;

export default App;

import { useWindowWidth } from '@react-hook/window-size';
import React, { CSSProperties } from 'react';
import TeamBackground from 'src/assets/photos/team.jpg';
import Button from 'src/components/Button';
import Text from 'src/components/Text';
import { primary, white } from 'src/utils/Colors';

const MOBILE_BREAKPOINT = 1000;

const LookForward = () => {
    const windowWidth = useWindowWidth();
    const styles = responsiveStyles(windowWidth);

    return (
        <div style={styles.teamBackground}>
            <div style={styles.overlay}>
                <div style={styles.teamTitle}>
                    <div style={styles.contentWrap}>
                        <Text
                            bold
                            color={white}
                            customStyle={{
                                fontSize:
                                    windowWidth > MOBILE_BREAKPOINT ? 80 : 64
                            }}
                        >
                            we look forward to seeing you at Yale!
                        </Text>
                        <Button
                            type="round"
                            rel="noopener noreferrer"
                            href="https://scilympiad.com/yuso"
                            shouldOpenInNewTab
                        >
                            Tournament
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    );
};

const responsiveStyles = (windowWidth: number) => ({
    teamTitle: {
        padding:
            windowWidth > MOBILE_BREAKPOINT
                ? '300px 120px 120px 120px'
                : '300px 0px 80px 0px',
        color: 'white',
        margin: '0 auto',
        textAlign: 'center',
        alignItems: 'center',
        maxWidth: 850
    } as CSSProperties,
    teamBackground: {
        backgroundColor: 'rgba(0, 0, 0, .5)',
        backgroundImage: `url( ${TeamBackground} )`,
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        width: '100%',
        height: '100vh',
        minHeight: 900
    } as CSSProperties,
    overlay: {
        width: '100%',
        height: '100vh',
        backgroundColor: 'rgba(0, 0, 0, .7)',
        minHeight: 900
    } as CSSProperties,
    contentWrap: {
        borderLeft: `8px solid ${primary}`,
        height: 225,
        paddingLeft: 10
    } as CSSProperties
});

export default LookForward;

import { useWindowWidth } from '@react-hook/window-size';
import React, { CSSProperties } from 'react';
import { useHistory } from 'react-router-dom';
import YusoLogo from 'src/assets/logos/yuso.png';

import CollapsedMenu from './CollapsedMenu';
import TextPath from './TextPath';

export interface TextRoute {
    name: string;
    route: string;
}

export const MOBILE_BREAKPOINT = 1000;
export const TEXT_ROUTES: TextRoute[] = [
    {
        name: 'About',
        route: '/about'
    },
    {
        name: 'Team',
        route: '/team'
    },
    {
        name: 'Tournament',
        route: '/tournament'
    },
    {
        name: 'Results',
        route: '/results'
    },
    {
        name: 'Contact',
        route: '/contact'
    },
    {
        name: 'Sponsors',
        route: '/sponsors'
    }
];

interface Props {
    simple?: boolean;
}

const NavBar = (props: Props) => {
    const { simple } = props;
    const history = useHistory();
    const windowWidth = useWindowWidth();

    return (
        <div style={styles.container}>
            <ul style={styles.routes}>
                <li style={styles.homePath}>
                    <img
                        src={YusoLogo}
                        onClick={() => history.push(`/`)}
                        className="hover-darken"
                        style={styles.yusoLogo}
                        alt=""
                    />
                </li>
                {windowWidth > MOBILE_BREAKPOINT && !simple && (
                    <div style={styles.textPathsWrap}>
                        {TEXT_ROUTES.map((route: TextRoute, i: number) => (
                            <TextPath
                                text={route.name}
                                route={route.route}
                                index={i}
                                key={i}
                            />
                        ))}
                    </div>
                )}
                {(windowWidth < MOBILE_BREAKPOINT || simple) && (
                    <CollapsedMenu />
                )}
            </ul>
        </div>
    );
};

const styles = {
    container: {
        width: '100%',
        position: 'absolute',
        zIndex: 3
    } as CSSProperties,
    yusoLogo: {
        height: 50,
        margin: '5px 30px 10px 30px',
        cursor: 'pointer'
    } as CSSProperties,
    routes: {
        height: 80,
        padding: 0,
        margin: '0 auto',
        textAlign: 'center'
    } as CSSProperties,
    homePath: {
        verticalAlign: 'middle',
        display: 'inline-block',
        listStyle: 'none',
        position: 'absolute',
        left: 0,
        top: 15
    } as CSSProperties,
    textPathsWrap: {
        position: 'absolute',
        top: 10,
        right: 30
    } as CSSProperties
};

export default NavBar;

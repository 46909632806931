import React, { CSSProperties } from 'react';
import Text from 'src/components/Text';
import { mineshaft, primary, tundora } from 'src/utils/Colors';
import Button from 'src/components/Button';

const Details = () => {
    return (
        <div style={styles.section}>
            <Text large black color={mineshaft} customStyle={{ margin: 0 }}>
                details.
            </Text>
            <hr style={styles.titleLine} />
            <Text
                customStyle={{ lineHeight: 1.5, marginTop: 30 }}
                color={tundora}
            >
                Yale Science Olympiad Invitational will be held in-person at the
                Yale campus on Saturday, February 8th, 2025! Registration for
                the invitational is open. Registration will close when all 60
                spots have been taken. Afterwards, teams can continue to sign up
                for our tournament waitlist.
            </Text>
            <Text
                customStyle={{ lineHeight: 1.5, marginTop: 30 }}
                color={tundora}
            >
                The registration fee for this year's in-person tournament is $75
                for one team. Each additional team will have a registration fee
                of $45. This fee will be waived for all CT and NY schools as
                well as Title 1 schools. Please reach out to yuso@yale.edu if
                this fee prevents you from participating.
            </Text>
            <Button
                type="round_small"
                rel="noopener noreferrer"
                href="https://docs.google.com/forms/u/6/d/e/1FAIpQLScHlT72rcW5mraGzGWPciQPvqhLscfJ66V8nGzyUwltnxeSLg/viewform?usp=send_form"
                shouldOpenInNewTab
            >
                Registration
            </Button>
        </div>
    );
};

const styles = {
    section: {
        width: '100%',
        display: 'inline-block',
        textAlign: 'left',
        marginBottom: 60
    } as CSSProperties,
    titleLine: {
        border: 0,
        borderTop: `3px solid ${primary}`,
        display: 'inline-block',
        width: 80,
        color: primary
    } as CSSProperties
};

export default Details;

import { useWindowWidth } from '@react-hook/window-size';
import React, { CSSProperties, useState } from 'react';
import People from 'src/assets/icons/people.png';
import Footer from 'src/components/Footer';
import NavBar from 'src/components/Navbar';
import Text from 'src/components/Text';
import { mineshaft, primary } from 'src/utils/Colors';
import { FULL_ROSTER, TeamInfo } from './Roster';
import TeamSection from './TeamSection';
import { EVENTS_SUPERVISORS, EventInfo } from './Events';
import EventProfile from './EventProfile';

const MOBILE_BREAKPOINT = 900;

const Team = () => {
    const windowWidth = useWindowWidth();
    const styles = responsiveStyles(windowWidth);
    const [toggle, setToggle] = useState(true);

    return (
        <>
            <NavBar simple />
            <div style={styles.contentWrap}>
                <div style={styles.content}>
                    <div style={styles.header}>
                        <Text
                            large
                            black
                            color={mineshaft}
                            customStyle={{ margin: 0 }}
                        >
                            our team<span style={{ color: primary }}>.</span>
                        </Text>
                        <hr style={styles.titleLine} />
                        <Text bold fontSize={20}>
                            the faces behind YUSO
                        </Text>
                        <div style={styles.toggleSwitch}>
                            <input
                                type="radio"
                                id="boardRadio"
                                checked={toggle}
                                onChange={() => setToggle(true)}
                                style={styles.radioInput}
                            />
                            <label
                                htmlFor="boardRadio"
                                style={{
                                    ...styles.radioLabel,
                                    ...(toggle && styles.checkedLabel)
                                }}
                            >
                                Board
                            </label>
                            <input
                                type="radio"
                                id="supervisorsRadio"
                                checked={!toggle}
                                onChange={() => setToggle(false)}
                                style={styles.radioInput}
                            />
                            <label
                                htmlFor="supervisorsRadio"
                                style={{
                                    ...styles.radioLabel,
                                    ...(!toggle && styles.checkedLabel)
                                }}
                            >
                                Supervisors
                            </label>
                        </div>
                    </div>
                    {toggle && _renderRoster(FULL_ROSTER)}
                    {!toggle && _renderEvents(EVENTS_SUPERVISORS)}
                </div>
                <img src={People} style={styles.peopleIcons} alt="" />
            </div>
            <Footer />
        </>
    );
};

const _renderRoster = (roster: TeamInfo[]) =>
    roster.map((teamInfo: TeamInfo, i: number) => (
        <TeamSection teamInfo={teamInfo} key={i} />
    ));

const _renderEvents = (events: EventInfo[]) => {
    const columnCount = 2;

    return (
        <>
            <Text
                fontSize={25}
                black
                color={mineshaft}
                customStyle={{ margin: 0 }}
            >
                events and supervisors
            </Text>
            <hr
                style={{
                    border: 0,
                    borderTop: '3px solid {primary}',
                    display: 'inline-block',
                    width: 80,
                    color: primary,
                    marginBottom: '4em'
                }}
            />
            <div
                style={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    justifyContent: 'space-between'
                }}
            >
                {events.map((e: EventInfo, i: number) => (
                    <div
                        key={i}
                        style={{
                            width: `${100 / columnCount - 5}%`,
                            boxSizing: 'border-box',
                            marginBottom: 20,
                            padding: '0 3em'
                        }}
                    >
                        <EventProfile event={e} />
                    </div>
                ))}
            </div>
        </>
    );
};

const responsiveStyles = (windowWidth: number) => ({
    contentWrap: {
        position: 'relative',
        width: '100%',
        overflow: 'hidden'
    } as CSSProperties,
    content: {
        padding:
            windowWidth > MOBILE_BREAKPOINT
                ? '160px 90px 180px 120px'
                : '160px 60px 120px 60px',
        minHeight: 'calc(100vh - 300px)',
        maxWidth: 1200,
        margin: '0 auto'
    } as CSSProperties,
    header: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        marginBottom: 60
    } as CSSProperties,
    titleLine: {
        border: 0,
        borderTop: `3px solid ${primary}`,
        display: 'inline-block',
        width: 80,
        color: primary,
        marginBottom: 10
    } as CSSProperties,
    peopleIcons: {
        height: 100,
        position: 'absolute',
        left: '50%',
        bottom: -40,
        transform: 'translate(-50%, -50%)'
    } as CSSProperties,
    toggleSwitch: {
        display: 'flex',
        width: '15em',
        alignItems: 'center',
        cursor: 'pointer',
        marginBottom: 20,
        background: '#eef',
        borderRadius: '1em'
    } as CSSProperties,
    radioInput: {
        display: 'none'
    } as CSSProperties,
    radioLabel: {
        width: '50%',
        padding: '0.6rem 0',
        textAlign: 'center',
        color: '#4C4848',
        cursor: 'pointer',
        transition: 'all 0.2s',
        borderRadius: '1em',
        fontFamily: 'Roboto-Bold'
    } as CSSProperties,
    checkedLabel: {
        color: 'white',
        background: primary
    } as CSSProperties
});

export default Team;

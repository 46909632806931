import React, { CSSProperties } from 'react';
import Text from 'src/components/Text';
import { mineshaft, primary, tundora } from 'src/utils/Colors';


const Rules = () => {
    return (
        <div style={styles.section}>
            <Text large black color={mineshaft} customStyle={{ margin: 0 }}>
                rules.
            </Text>
            <hr style={styles.titleLine} />
            <Text
                customStyle={{ lineHeight: 1.5, marginTop: 30 }}
                color={tundora}
            >
                YUSO follows the standard policies, guidelines, and regulations of the national Science Olympiad tournament for Division C.
                <br/>
                Each participating school may register up to <b>2 teams consisting of up to 15 students</b>, in addition to 5 alternates. A full list of rules can be found on the <a href="https://www.soinc.org/rules-2025">official Science Olympiad website</a>.
            </Text>
        </div>
    );
}

const styles = {
    section: {
        width: '100%',
        display: 'inline-block',
        textAlign: 'left',
        marginBottom: 60
    } as CSSProperties,
    titleLine: {
        border: 0,
        borderTop: `3px solid ${primary}`,
        display: 'inline-block',
        width: 80,
        color: primary
    } as CSSProperties
};

export default Rules;
import { useWindowWidth } from '@react-hook/window-size';
import React, { CSSProperties } from 'react';
import Text from 'src/components/Text';
import { mineshaft, primary } from 'src/utils/Colors';

const MOBILE_BREAKPOINT = 1000;

const Blurb = () => {
    const windowWidth = useWindowWidth();
    const styles = responsiveStyles(windowWidth);

    return (
        <div style={styles.blurb}>
            <Text
                title_medium
                black
                color={mineshaft}
                customStyle={{ margin: 0 }}
            >
                the{' '}
                <span
                    style={{
                        textDecorationLine: 'underline',
                        textDecorationColor: `${primary}`
                    }}
                >
                    first
                </span>{' '}
                collegiate invitational
            </Text>
            <Text
                title_medium
                black
                color={mineshaft}
                customStyle={{ margin: '5px 5px 0 5px', padding: 0 }}
            >
                in science olympiad history
                <span style={{ color: primary }}>.</span>
            </Text>
        </div>
    );
};

const responsiveStyles = (windowWidth: number) => ({
    blurb: {
        padding:
            windowWidth > MOBILE_BREAKPOINT
                ? '320px 120px 0px 120px'
                : '100px 60px 0px 60px',
        minHeight: 'calc(100vh - 450px)',
        maxWidth: 800,
        textAlign: 'center',
        margin: '0 auto'
    } as CSSProperties
});

export default Blurb;

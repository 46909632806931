export interface Sponsor {
    name: string;
    tier: string;
    websiteLink: string;
    sponsorSrc: string;
    description: string;
    desiredSize?: string;
}

export const SPONSORS: Sponsor[] = [
    {
        name: 'Jane Street',
        tier: 'gold',
        websiteLink: 'https://www.janestreet.com/',
        sponsorSrc: '/img/sponsors/jane_street.png',
        description:
            'Jane Street is a quantitative trading firm and liquidity provider with a unique focus on technology and collaborative problem solving.'
    },
    {
        name: 'Yale Earth and Planetary Sciences',
        tier: 'gold',
        websiteLink: 'https://earth.yale.edu/',
        sponsorSrc: '/img/sponsors/yale_eps.png',
        description: ''
    },
    {
        name: 'Yale Molecular, Cellular and Developmental Biology',
        tier: 'bronze',
        websiteLink: 'https://mcdb.yale.edu/',
        sponsorSrc: '/img/sponsors/mcdb.png',
        description: ''
    },
    {
        name: 'Yale Genetics',
        tier: 'bronze',
        websiteLink: 'https://medicine.yale.edu/genetics/',
        sponsorSrc: '/img/sponsors/yale_gen.png',
        description: '',
        desiredSize: '30%'
    },
    {
        name: 'Yale SEAS',
        tier: 'bronze',
        websiteLink: 'https://seas.yale.edu/about',
        sponsorSrc: '/img/sponsors/yale_seas.jpg',
        description: '',
        desiredSize: '30%'
    },
    {
        name: 'Simplex Sciences',
        tier: 'bronze',
        websiteLink: 'https://www.simplexsciences.com/',
        sponsorSrc: '/img/sponsors/simplex.png',
        description: '',
        desiredSize: '35%'
    },
    {
        name: 'Yale Chemistry',
        tier: 'blue',
        websiteLink: 'https://chem.yale.edu/',
        sponsorSrc: '/img/sponsors/yale_chem.png',
        description: '',
        desiredSize: '40%'
    }
];

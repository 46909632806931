import { useWindowWidth } from '@react-hook/window-size';
import React, { CSSProperties } from 'react';
import { useHistory } from 'react-router-dom';
import Text from 'src/components/Text';
import { mineshaft, primary, white } from 'src/utils/Colors';

import { TEXT_ROUTES, TextRoute } from '../Navbar';

const MOBILE_BREAKPOINT = 650;

const Footer = () => {
    const history = useHistory();
    const windowWidth = useWindowWidth();
    const styles = responsiveStyles(windowWidth);

    return (
        <div style={styles.container}>
            <div style={styles.content}>
                <div style={styles.column}>
                    {TEXT_ROUTES.map((textRoute: TextRoute, i: number) => (
                        <Text
                            onPress={() => {
                                history.push(textRoute.route);
                                window.scrollTo(0, 0);
                            }}
                            fontSize={24}
                            blueHover
                            black
                            color={white}
                            customStyle={styles.textRoute}
                            key={i}
                        >
                            {textRoute.name.toLowerCase()}
                        </Text>
                    ))}
                </div>
                <div style={styles.column}>
                    <Text
                        fontSize={20}
                        bold
                        medium_large
                        color={white}
                        customStyle={{ margin: '0px 0px 15px 0px' }}
                    >
                        contact us!
                    </Text>
                    <a
                        href="mailto:yuso@yale.edu"
                        className="hover-darken"
                        style={styles.emailLink}
                    >
                        yuso@yale.edu
                    </a>
                    <Text
                        fontSize={20}
                        bold
                        color={white}
                        customStyle={{ margin: '40px 0px 15px 0px' }}
                    >
                        explore
                    </Text>
                    <div>
                        <a
                            href="https://www.facebook.com/yuso.yale/"
                            style={{ textDecoration: 'none' }}
                        >
                            <i
                                className="fab fa-facebook-square hover-darken"
                                aria-label="facebook"
                                style={styles.socialIcon}
                            />
                        </a>
                        <a
                            href="https://www.instagram.com/yale.scioly/"
                            style={{ textDecoration: 'none' }}
                        >
                            <i
                                className="fab fa-instagram hover-darken"
                                aria-label="instagram"
                                style={styles.socialIcon}
                            />
                        </a>
                    </div>
                </div>
            </div>
        </div>
    );
};

const responsiveStyles = (windowWidth: number) => ({
    container: {
        position: 'relative',
        background: mineshaft,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        zIndex: 3
    } as CSSProperties,
    content: {
        flex: 1,
        display: 'flex',
        maxWidth: 600,
        padding: '60px 140px 60px 140px',
        flexDirection: windowWidth > MOBILE_BREAKPOINT ? 'row' : 'column',
        justifyContent: 'space-between'
    } as CSSProperties,
    column: {
        display: 'flex',
        flexDirection: 'column',
        textAlign: windowWidth > MOBILE_BREAKPOINT ? 'left' : 'center',
        marginBottom: windowWidth > MOBILE_BREAKPOINT ? 0 : 40
    } as CSSProperties,
    textRoute: {
        margin: '6px 0px 6px 0px',
        cursor: 'pointer'
    } as CSSProperties,
    emailLink: {
        textDecoration: 'none',
        fontFamily: 'Roboto-Regular',
        color: primary,
        margin: 0
    } as CSSProperties,
    socialWrap: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row'
    } as CSSProperties,
    socialIcon: {
        fontSize: 20,
        marginLeft: windowWidth > MOBILE_BREAKPOINT ? 0 : 5,
        marginRight: windowWidth > MOBILE_BREAKPOINT ? 15 : 5,
        cursor: 'pointer',
        color: primary
    } as CSSProperties
});

export default Footer;

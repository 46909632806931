export interface SupervisorInfo {
    supervisorName: string;
    collegeYear: string;
};

export interface EventInfo {
    eventName: string;
    supervisors: SupervisorInfo[];
}

const supervisorNameList = [
    "Jiya Mody",
    "Christian Kang",
    "Sarah Li",
    "Jacob Leshnower",
    "Brandon Ngo",
    "George Sun",
    "David Wang",
    "Sean Liu",
    "Kevin Chua",
    "Priya Gill",
    "Jacob Feldman",
    "Jacob Feldman",
    "Justin Pan",
    "Marco Frigo",
    "Siddiq Mohammed",
    "Lily Shukla",
    "Vasilije Pantelic",
    "Quinn Ennis",
    "John Wood",
    "Andrew Brites",
    "William Noguera",
    "Shrayen Patel",
    "Ashriitha Shanmugam",
    "Tanishk Natu",
    "Lauren Lee",
    "Pranet Sharma"
]

const collegeYearList = [
    'Yale College 2027',
    'Yale College 2026',
    'Yale College 2027',
    'Yale College 2027',
    'Yale College 2027',
    'Yale School of Medicine 2026',
    'Yale College 2025',
    'Yale College 2026',
    'Yale College 2027',
    'Yale College 2027',
    'Yale College 2027',
    'Yale College 2027',
    'Yale College 2027',
    'University of Illinois at Urbana-Champaign 2027',
    'Yale College 2027',
    'Yale College 2027',
    'Yale College 2025',
    'Yale College 2026',
    'Yale College 2026',
    'Villanova University 2027',
    'UW Madison 2027',
    'UT Austin 2024',
    'University of Alabama at Birmingham 2025',
    'University of Cincinnati 2027',
    'UC Berkeley 2026',
    'Yale College 2026',
]

const eventsList = [
    "Anatomy & Physiology",
    "Air Trajectory",
    "Anatomy & Physiology",
    "Disease Detectives",
    "Codebusters",
    "Flight",
    "Optics",
    "Flight",
    "Scrambler",
    "Disease Detectives",
    "Chem Lab",
    "Forensics",
    "Fermi Questions",
    "Astronomy",
    "Wind Power",
    "Geologic Mapping",
    "Experimental Design",
    "Codebusters",
    "Fermi Questions",
    "Dynamic Planet",
    "Fossils",
    "Forestry",
    "Disease Detectives",
    "Dynamic Planet",
    "Ecology",
    "Experimental Design"
]

export const EVENTS_SUPERVISORS: EventInfo[] = [];
const included: Map<string, number> = new Map();

for(let i = 0; i < eventsList.length; i++) {
    const e = eventsList[i];
    if(!included.has(e)) {
        included.set(e, i);
        EVENTS_SUPERVISORS[i] = {
            eventName: eventsList[i],
            supervisors: [{
                supervisorName: supervisorNameList[i],
                collegeYear: collegeYearList[i]
            }]
        }
    } else {
        const index = included.get(e);
        if(index !== undefined) {
            EVENTS_SUPERVISORS[index].supervisors.push({
                supervisorName: supervisorNameList[i],
                collegeYear: collegeYearList[i]
            })
        }
    }
}

EVENTS_SUPERVISORS.sort((a, b) => a.eventName.localeCompare(b.eventName));